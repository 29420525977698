import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "../components/layout"

const StandardPageTemplate = ({ data, location }) => {
    const { mdx } = data
    const { body, frontmatter } = mdx

    return (
        <Layout location={location} pageTitle={frontmatter.title}>
            <div className="px-5">
                <MDXProvider>
                    <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
            </div>
        </Layout>
    )
}

export default StandardPageTemplate

export const pageQuery = graphql`
    query PageQuery($id: String!) {
        site {
            siteMetadata {
                title
            }
        }
        mdx(id: {eq: $id}) {
            id
            body
            slug
            frontmatter {
                title
            }
        }
    }
`
